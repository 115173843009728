import { HTTPClient } from '@/api/HTTPClient';

import { IBookMark } from '@/models';

import { CONTENT_TYPE } from '@/utils';

export interface IBookMarkService {
  getBookMarkList({
    contentType,
    page,
    size,
  }: {
    contentType: CONTENT_TYPE;
    page: number;
    size?: number;
  }): Promise<{ content: IBookMark[]; totalElements: number; last: boolean }>;

  createBookMark({
    contentId,
    contentType,
  }: {
    contentId: number;
    contentType: CONTENT_TYPE;
  }): Promise<number>;

  deleteBookMark(bookMarkId: number): Promise<void>;
}

class BookMarkSearvice extends HTTPClient implements IBookMarkService {
  async getBookMarkList({
    page,
    size = 20,
    contentType,
  }: Parameters<IBookMarkService['getBookMarkList']>[0]) {
    const { data } = await this.http.get(`${contentType}`, {
      params: {
        page,
        size,
      },
    });
    return {
      content: data.content,
      totalElements: data.totalElements,
      last: data.last,
    };
  }

  async createBookMark({
    contentId,
    contentType,
  }: Parameters<IBookMarkService['createBookMark']>[0]) {
    const { data } = await this.http.post('', {
      contentId,
      contentType,
    });

    return data;
  }

  async deleteBookMark(bookMarkId: Parameters<IBookMarkService['deleteBookMark']>[0]) {
    await this.http.delete(`${bookMarkId}`);
  }
}

export const bookMarkSearvice = new BookMarkSearvice('book-mark');












































































import { ICategory } from '@/models';
import { bookSearvice } from '@/services';
import { computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api';
import { CategorySideBar, Pagination, BookCard, BookCardSkeleton, SearchBar } from '@/components';
import { BOOK_SEARCH_TYPE, CONTENT_TYPE, LEARNMOA_TYPE, normalizeParams } from '@/utils';
import { Route } from 'vue-router';
import { IBook } from '@/models/book';
import { bookMarkSearvice } from '@/services/bookMark';

export default defineComponent({
  name: 'BookList',
  components: {
    CategorySideBar,
    Pagination,
    BookCard,
    BookCardSkeleton,
    SearchBar,
  },
  setup(_, { root }) {
    const loading = ref(false);

    const pagination = reactive({
      page: 1,
      size: 20,
      totalCount: 0,
    });

    const filter = reactive<{ searchType: BOOK_SEARCH_TYPE; searchKey: string }>({
      searchType: BOOK_SEARCH_TYPE.All,
      searchKey: '',
    });

    const bookList = ref<IBook[]>([]);

    const category = ref('');
    const categories = ref<ICategory[]>([]);

    const selectedCategoryName = computed(
      () => categories.value.find(item => item.val === category.value)?.nm || '전체',
    );

    function onOpenLinkClicked(link: string) {
      window.open(link, '_blank');
    }

    async function fetchCategoryList() {
      try {
        const response = await bookSearvice.getCategories();
        categories.value = response;
      } catch {
        // pass
      }
    }

    async function fetchData() {
      loading.value = true;

      try {
        const params = {
          category: category.value,
          size: pagination.size,
          page: pagination.page,

          bookSearchKey: filter.searchKey,
          bookSearchType: filter.searchType,
        };

        console.log(filter.searchKey);

        const response = await bookSearvice.getBooks({
          ...normalizeParams(params),
          category: category.value,
        });

        bookList.value = [...response.content];

        pagination.totalCount = response.totalElements;
      } catch {
        // pass
      } finally {
        loading.value = false;
      }
    }

    function updateQueryString() {
      root.$router
        .push({
          query: {
            ...normalizeParams({
              ...root.$route.query,

              page: pagination.page,
              size: pagination.size,

              bookSearchKey: filter.searchKey,
              bookSearchType: filter.searchType,
            }),
          },
        })
        .catch(() => false);
    }

    function onChangePage() {
      window.scrollTo({ top: 0 });

      updateQueryString();
    }

    function submitSearch() {
      pagination.page = 1;
      updateQueryString();
    }

    function setSearchKeyWithQueryString(query: Route['query']) {
      filter.searchKey = String(query.bookSearchKey || '');
    }

    function setCategoryWithQueryString(query: Route['query']) {
      category.value = (query.category || '') as string;
    }

    function setPaginationWithQueryString(query: Route['query']) {
      pagination.page = Number(query.page) || 1;
      pagination.size = Number(query.size) || 20;
    }

    async function handleToggleBookMark({
      marked,
      bookId,
      listIndex,
      bookMarkId,
    }: {
      marked: boolean;
      bookId: number;
      listIndex: number;
      bookMarkId: number | null;
    }) {
      try {
        if (marked) {
          const newBookMarkId = await bookMarkSearvice.createBookMark({
            contentId: bookId,
            contentType: CONTENT_TYPE.Book,
          });

          bookList.value[listIndex].bookMarkId = newBookMarkId;
        } else {
          if (!bookMarkId) return;
          await bookMarkSearvice.deleteBookMark(bookMarkId);
        }

        bookList.value[listIndex].bookMarked = marked;
      } catch {
        // pass
      }
    }

    watch(
      () => root.$route,
      ({ query }) => {
        setCategoryWithQueryString(query);
        setPaginationWithQueryString(query);
        setSearchKeyWithQueryString(query);

        fetchData();
      },
      {
        deep: true,
        immediate: true,
      },
    );

    onMounted(() => {
      fetchCategoryList();
    });

    return {
      LEARNMOA_TYPE,

      loading,
      bookList,
      pagination,
      filter,
      categories,

      selectedCategoryName,

      onChangePage,
      submitSearch,
      onOpenLinkClicked,

      handleToggleBookMark,
    };
  },
});
